import { Routes, Route } from "react-router-dom";

import { configureAutoTrack } from "aws-amplify/analytics";

import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { /*amber,*/ deepPurple } from "@mui/material/colors";

import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import AdminInfo from "./components/AdminInfo";
import Terms from "./components/Terms";
import Search from "./components/Search";
import Stop from "./components/Stop";
import Place from "./components/Place";
import Trip from "./components/Trip";
import Vehicle from "./components/Vehicle";
import NotFound from "./components/NotFound";

// import logo from './logo.svg';
// import './App.css';

// const defaultTheme = createTheme();

const theme = createTheme({
  // typography: {
  // The default value is 14px.
  // In Chinese and Japanese the characters are usually larger,
  // so a smaller fontsize may be appropriate.
  //   fontSize: 12,
  // },
  palette: {
    primary: { main: deepPurple[500] },
    secondary: { main: "#ffc400" },
    background: { default: "#f5f5f5" },
  },
  // components: {
  //   MuiCardActionArea: {
  //     styleOverrides: {
  //       root: {
  //         backgroundColor: "#fafafa",
  //       },
  //     },
  //   },
  // },
});

function App() {
  // Pinpoint
  configureAutoTrack({
    // REQUIRED, turn on/off the auto tracking
    enable: true,
    // REQUIRED, the event type, it's one of 'event', 'pageView' or 'session'
    type: "session",
    // OPTIONAL, additional options for the tracked event.
    // options: {
    //   // OPTIONAL, the attributes of the event
    //   attributes: {
    //     customizableField: "attr",
    //   },
    // },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="admn" element={<AdminInfo />} />
        <Route path="search" element={<Search />} />
        <Route path="stop">
          <Route path=":stopId" element={<Stop />} />
        </Route>
        <Route path="place">
          <Route path=":stopId" element={<Place />} />
        </Route>
        <Route path="trip">
          <Route path=":tripId" element={<Trip />} />
        </Route>
        <Route path="vehicle">
          <Route path=":vehicleId" element={<Vehicle />} />
        </Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
