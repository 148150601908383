import React from "react";
import { useHits } from "react-instantsearch";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { HeadsignList } from "./HeadsignList";

function Hit({ hit }) {
  const stopUrl = "/stop/" + hit.objectID;
  let stopName = hit.stopName;
  if (hit.platform_code && hit.platform_code.length > 0) {
    stopName += " のりば " + hit.platform_code;
  }
  return (
    <Paper
      sx={{ p: 1, mt: 1, bgcolor: "grey.300" }}
      itemScope
      itemType="https://schema.org/BusStop"
    >
      <Box>
        <Link href={stopUrl}>
          <Typography component="div" variant="h6">
            {stopName}
          </Typography>
        </Link>
        <Typography>{hit.stopKana}</Typography>
      </Box>
      <meta itemProp="name" content={hit.stopName} />
      <meta itemProp="latitude" content={hit._geoloc.lat} />
      <meta itemProp="longitude" content={hit._geoloc.lng} />
      <HeadsignList headsign={hit.headsigns} />
    </Paper>
  );
}

export function CustomHits(props) {
  const { items, sendEvent } = useHits(props);

  return (
    <Box>
      {items.map((hit) => (
        <Hit hit={hit} key={hit.objectID} />
      ))}
    </Box>
  );
}
